.sider-logo {
    width: 120px;
    height: 31px;
    background: rgba(255,255,255,.2);
    margin: 16px 28px 16px 0;
    float: left;
}

.main-layout{
  padding: 24px 24px 24px 24px;
  width: 100%;
  min-height: 100%;
}

.layout-content{
  background: #fff;
  padding: 24px;
  margin: 0;
  min-height: 650px;
}
