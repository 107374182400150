.student-detail-card {
  margin-bottom: 40px;
}

.student-overview-course-stat {
  text-align: left;
}

.student-overview-current-course {
  color: green;
  font-weight: bolder;
}

.student-overview-next-course {
  color: blue;
  font-weight: bolder;
}

.student-overview-divider {
  padding-top: 20px;
}
