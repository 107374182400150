.header-buttons {
  text-align: right;
}

.course-finished {
  color: gray;
  font-weight: bolder;
}
.course-waiting {
  color: blue;
  font-weight: bolder;
}
.course-ongoing {
  color: green;
  font-weight: bolder;
}
