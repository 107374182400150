.location-card {
  margin-bottom: 20px;
  width: 100%;
}

.duration-stat {
  font-weight: normal;
  font-size: large;
}

.custom-tooltip {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.96);
  color: #646464;
  width: 250px;
  height: auto;
  border-radius: 3px;
  font-size: 14px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px #aeaeae;
}

.custom-tooltip-title {
  margin: 12px 12px 0 12px;
  padding-bottom: 8px;
  font-size: 14px;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e9e9e9;
}

.custom-tooltip-value {
  display: flex;
  margin: 8px 12px 0 12px;
  padding-bottom: 8px;
  font-size: 35px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e9e9e9
}

.custom-tooltip-list {
  list-style: none;
}

.custom-tooltip-value span {
  position: absolute;
  top: -10px;
  font-size: 12px
}