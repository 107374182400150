

.sider-logo {
    width: 120px;
    height: 31px;
    background: rgba(255,255,255,.2);
    margin: 16px 28px 16px 0;
    float: left;
}

.main-layout{
  padding: 24px 24px 24px 24px;
  width: 100%;
  min-height: 100%;
}

.layout-content{
  background: #fff;
  padding: 24px;
  margin: 0;
  min-height: 650px;
}

.course-finished {
  color: gray;
  font-weight: bolder;
}
.course-waiting {
  color: blue;
  font-weight: bolder;
}
.course-ongoing {
  color: green;
  font-weight: bolder;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
.detailed-classroom-usage-graph{
  width: 100%;
  margin-bottom: 20px;
}
.student-detail-card {
  margin-bottom: 40px;
}

.student-overview-course-stat {
  text-align: left;
}

.student-overview-current-course {
  color: green;
  font-weight: bolder;
}

.student-overview-next-course {
  color: blue;
  font-weight: bolder;
}

.student-overview-divider {
  padding-top: 20px;
}

.teacher-overview-course-stat {
  margin-bottom: 20px;
}
.location-card {
  margin-bottom: 20px;
  width: 100%;
}

.duration-stat {
  font-weight: normal;
  font-size: large;
}

.custom-tooltip {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.96);
  color: #646464;
  width: 250px;
  height: auto;
  border-radius: 3px;
  font-size: 14px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px #aeaeae;
}

.custom-tooltip-title {
  margin: 12px 12px 0 12px;
  padding-bottom: 8px;
  font-size: 14px;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e9e9e9;
}

.custom-tooltip-value {
  display: flex;
  margin: 8px 12px 0 12px;
  padding-bottom: 8px;
  font-size: 35px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e9e9e9
}

.custom-tooltip-list {
  list-style: none;
}

.custom-tooltip-value span {
  position: absolute;
  top: -10px;
  font-size: 12px
}
.header-buttons {
  text-align: right;
}

.course-finished {
  color: gray;
  font-weight: bolder;
}
.course-waiting {
  color: blue;
  font-weight: bolder;
}
.course-ongoing {
  color: green;
  font-weight: bolder;
}

